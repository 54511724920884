import * as React from "react";
import {
  Edit,
  CreateProps,
  ReferenceInput,
  SimpleForm,
  TextInput,
  SelectInput,
  ImageField,
  required,
  ImageInput,
  useRecordContext,
  useDataProvider,
  useTranslate,
  ReferenceArrayInput,
} from "react-admin";
import { Box, CardContent, Divider, Avatar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import BusinessIcon from "@material-ui/icons/Business";
import MarkDownInputWithField from "../../components/MarkDownInputWithField";
import { CheckboxGroupInput } from "react-admin";
import axios from "axios";
import feathersClient from "../../feathersClient";
import FaceIcon from "@material-ui/icons/Face";
import Chip from "@material-ui/core/Chip";
import _ from "lodash";
import Editor from "../../components/RichTextInput";

const useStyles = makeStyles({
  createBy: {

    // This is JSS syntax to target a deeper element using css selector, here the svg icon for this button
  },
  chip: {
    marginTop: 16,
    width: "unset",
  },
});

const PreviewImage = ({ record, source }) => {
  if (typeof record === "string") {
    record = {
      [source]: record,
    };
  }

  return <ImageField record={record} source={source} />;
};

export const ArticleEdit = (props) => {
  const [category, setCategory] = React.useState([]);
  const [order, setOrder] = React.useState(
    [1, 2, 3, 4, 5, 6].map((item) => ({
      id: item,
      name: item.toString(),
    }))
  );

  const [currentUser, setCurrentUser] = React.useState({});
  const classes = useStyles();

  React.useEffect(async () => {
    axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
        timeout: 1000,
      })
      .get("/categories")
      .then((data) => {
        if (data && data.data && data.data.total > 0) {
          setCategory(data.data.data);
        }
      })
      .finally(async () => {
        const data = await feathersClient.get("authentication");
        setCurrentUser(data.user);
      });
  }, []);

  React.useEffect(async () => {
    axios
      .create({
        baseURL: process.env.REACT_APP_API_URL,
      })
      .get("/articles?isOrder[$gte]=1")
      .then((data) => {
        const orders = [1, 2, 3, 4, 5, 6];
        const toRemove = new Set();

        if (data && data.data && data.data.total > 0) {
          data.data.data
            .filter((item) => item._id !== props.id)
            .forEach((item) => toRemove.add(item.isOrder));

          const newOrder = orders.filter((x) => !toRemove.has(x));

          const mapped = newOrder.map((number) => ({
            id: number.toString(),
            name: number.toString(),
          }));
          setOrder(mapped);
        } else {
          const mapped = orders.map((number) => ({
            id: number.toString(),
            name: number.toString(),
          }));
          setOrder(mapped);
        }
      });
  }, []);

  return (
    <Edit {...props} actions={false}>
      <SimpleForm component={CustomLayout} redirect="list">
        <ImageInput source="avatar">
          <PreviewImage source="src" />
        </ImageInput>
        <TextInput source="title" validate={required()} fullWidth />
        <Editor source="body" fullWidth  label="Nội dung" theme="snow"/>
        <CheckBoxGroup categories={category} />
        <div>Tác giả</div>
        <TextInput
            source="createBy"
            className={classes.createBy}
            fullWidth
        />
        <SelectInput
          style={{ marginTop: 24 }}
          source="isOrder"
          choices={[{ id: "", name: "None" }, ...order]}
          defaultValue={""}
        />

      </SimpleForm>
    </Edit>
  );
};

const CheckBoxGroup = (props) => {
  return (
    <CheckboxGroupInput
      source="categories"
      optionText="name"
      optionValue="_id"
      choices={props.categories}
    />
  );
};

const CustomLayout = (props) => (
  <CardContent>
    <Box display="flex">
      <Box paddingTop={1}>
        <Avatar>
          <BusinessIcon />
        </Avatar>
      </Box>
      <Box ml={2} flex="1" maxWidth={796}>
        {props.children}
      </Box>
    </Box>
  </CardContent>
);

const CustomDivider = () => (
  <Box mb={2}>
    <Divider />
  </Box>
);
