// Initially copied from https://github.com/marmelab/react-admin/blob/master/packages/ra-input-rich-text/src/index.js
import React from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import FormControl from "@material-ui/core/FormControl";
import "react-quill/dist/quill.snow.css";
import {addField} from "ra-core";
import {withStyles} from "@material-ui/core/styles";

const styles = {};
class Editor extends React.Component {
  static propTypes = {
    input: PropTypes.object,
    source: PropTypes.string,
  };
  constructor(props) {
    super(props);
    this.state = { input: "" };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(html) {
    this.setState({ input: html });
    this.props.input.onChange(html);
  }

  render() {
    console.log("----- this.state.input", this.state.input)
    console.log("----- this.state.props", this.props)
    console.log("----- this.props.record.body", this.props.record.body)
    return (
        <FormControl fullWidth={true} className="ra-input-mde">
          <ReactQuill
            theme="snow"
            onChange={this.handleChange}
            value={this.state.input || this.props.record.body}
            modules={Editor.modules}
            formats={Editor.formats}
            bounds={".app"}
            source="body"
            label="Nội dung"
            fullWidth
            placeholder={this.props.placeholder}
          />
        </FormControl>
    );
  }
}

/*
 * Quill modules to attach to editor
 * See https://quilljs.com/docs/modules/ for complete options
 */

/*
 * Quill editor formats
 * See https://quilljs.com/docs/formats/
 */
Editor.formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

/*
 * PropType validation
 */
Editor.propTypes = {
  placeholder: PropTypes.string,
};

Editor.modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    // toggle to add extra line breaks when pasting HTML:
    matchVisual: false,
  },
};
const MarkDownInputWithField = addField(withStyles(styles)(Editor));
MarkDownInputWithField.defaultProps = {
  addLabel: true,
  fullWidth: true,
};
export default MarkDownInputWithField;
